import { IconInterface } from "@/components/Icon/IconInterface";

export const KlarnaIcon = ({ className }: IconInterface) => (
  <svg id="Layer_1" className={className} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 638 147.35">
    <title>Klarna Logo Vector</title>
    <g id="UI">
      <g id="Product---business-_desktop_" data-name="Product---business-(desktop)">
        <path
          id="Page-1"
          d="M316.78,571.1h30.86V426.32H316.78Zm195.43-83.57V474.67H483.16v96.69h29.05V520.7c0-14.66,16.46-21.09,28-18h.25v-28c-11.83-.26-22.63,4.89-28.29,12.86ZM413,548.73c-15.17,0-27.26-11.57-27.26-26s12.35-26,27.26-26c15.18,0,27.26,11.57,27.26,26s-12.34,26-27.26,26Zm27-67.63a51.13,51.13,0,0,0-80.48,41.66,51,51,0,0,0,50.91,50.92A53,53,0,0,0,440,564.42v6.68h25.21V474.41H440ZM613,471.84c-11.57,0-22.63,3.6-29.83,13.37v-10.8H554.13V571.1h29.06v-53c0-14.66,9.77-21.86,21.86-21.86,12.6,0,20.06,7.72,20.06,21.6v53h29.06V509.39c0-22.12-18-37.55-41.15-37.55ZM800.74,536.9A18.26,18.26,0,1,0,819,555.16a18.33,18.33,0,0,0-18.26-18.26Zm-83.06,11.83c-15.17,0-27.26-11.57-27.26-26s12.35-26,27.26-26c15.17,0,27.26,11.57,27.26,26,.26,14.4-12.09,26-27.26,26Zm27.26-67.63a51,51,0,1,0-29.57,92.58,53,53,0,0,0,29.57-9.26v6.68h25.2V474.41h-25.2Zm43.46-4.89h1.29a1.18,1.18,0,0,1,1.28,1c0,.77-.26,1-1.28,1H788.4Zm0,3.35h1.29l1.28,2.82h1.29L791,479.56a2,2,0,0,0,1.29-2.06,2.38,2.38,0,0,0-2.57-2.32h-2.58v7.2h1.29v-2.82ZM181,571.1h32.14V426.32H181ZM295.69,426.32H264.58a85.72,85.72,0,0,1-46.81,74.84l48.09,69.94H305l-43.72-63.51a116.16,116.16,0,0,0,34.46-81.27Zm493.74,46.81a5.66,5.66,0,1,1-5.66,5.66,5.7,5.7,0,0,1,5.66-5.66Zm0,12.6a6.95,6.95,0,1,0-6.95-7,6.92,6.92,0,0,0,6.95,7Z"
          transform="translate(-181 -426.32)"
        />
      </g>
    </g>
  </svg>
);
