import { IconInterface } from "@/components/Icon/IconInterface";

export const SepaIcon = ({ className }: IconInterface) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 410.000000 135.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,135.000000) scale(0.100000,-0.100000)" fill="#1d52a8" stroke="none">
      <path
        d="M1663 1340 c-52 -11 -112 -41 -140 -72 -55 -58 -69 -168 -33 -244 26
-52 73 -85 185 -129 118 -47 135 -59 135 -96 0 -46 -16 -67 -62 -80 -51 -13
-133 -4 -206 23 -29 11 -55 18 -56 16 -4 -5 -24 -133 -21 -136 12 -14 137 -45
201 -49 187 -12 303 75 304 230 0 124 -56 178 -246 241 -64 21 -94 48 -94 86
0 61 98 85 224 56 38 -9 70 -15 71 -14 5 6 24 132 21 135 -2 3 -28 11 -57 19
-71 19 -174 25 -226 14z"
      />
      <path
        d="M2120 955 l0 -375 255 0 255 0 0 75 0 75 -175 0 -175 0 0 85 0 85
140 0 140 0 0 65 0 64 -137 3 -138 3 0 75 0 75 173 3 172 2 0 70 0 70 -255 0
-255 0 0 -375z"
      />
      <path
        d="M2780 955 l0 -376 83 3 82 3 3 121 3 122 108 4 c96 3 114 6 166 33
50 26 63 39 88 85 26 50 28 62 25 140 -4 102 -25 150 -80 190 -55 40 -115 50
-308 50 l-170 0 0 -375z m345 229 c26 -10 45 -59 45 -114 0 -44 -5 -56 -29
-81 -28 -27 -34 -29 -110 -29 l-81 0 0 115 0 115 79 0 c44 0 87 -3 96 -6z"
      />
      <path
        d="M3619 1303 c-6 -16 -63 -179 -126 -363 -64 -184 -118 -341 -121 -348
-3 -10 15 -12 84 -10 l88 3 21 70 21 70 139 0 139 0 21 -70 21 -70 92 -3 c51
-1 92 -1 92 1 0 4 -209 601 -241 690 l-21 57 -99 0 c-99 0 -100 0 -110 -27z
m156 -258 c21 -77 40 -148 43 -157 4 -16 -5 -18 -93 -18 -87 0 -96 2 -91 18 3
9 24 83 47 165 24 81 46 144 49 140 4 -5 24 -71 45 -148z"
      />
      <path
        d="M987 900 l-216 -290 -233 -2 -233 -3 -85 -280 -85 -280 569 -3 c313
-1 571 0 574 2 2 3 -32 130 -75 283 l-79 278 -92 3 c-51 1 -92 5 -92 7 0 3 80
112 179 244 98 131 181 243 185 249 5 9 -81 82 -97 82 -2 0 -102 -130 -220
-290z m-247 -320 c0 -6 -19 -37 -42 -68 -26 -34 -55 -93 -74 -147 -32 -92 -52
-110 -46 -42 4 43 -3 46 -35 15 l-23 -22 6 48 c6 43 5 48 -11 45 -10 -2 -34
-30 -53 -62 -22 -39 -45 -64 -65 -73 l-32 -14 30 2 c25 3 37 14 74 71 24 37
46 65 48 63 3 -2 -3 -32 -13 -65 -9 -34 -15 -64 -12 -67 3 -2 18 12 34 33 l29
38 5 -35 c3 -20 11 -36 20 -37 33 -6 174 115 256 219 l80 103 97 3 96 3 10
-38 c6 -21 39 -139 74 -263 l64 -225 -545 -3 c-300 -1 -546 -1 -548 1 -3 2 56
202 126 430 l31 97 209 0 c139 0 210 -4 210 -10z"
      />
      <path
        d="M385 180 c-4 -7 103 -10 314 -10 207 0 321 4 321 10 0 14 -627 14
-635 0z"
      />
      <path
        d="M1250 988 c-84 -114 -87 -118 -68 -118 7 1 47 46 88 102 84 114 87
118 68 118 -7 -1 -47 -46 -88 -102z"
      />
      <path
        d="M3572 389 c-12 -5 -22 -12 -22 -16 1 -39 73 -45 78 -7 4 25 -22 35
-56 23z"
      />
      <path
        d="M3018 383 c-16 -4 -18 -21 -18 -184 l0 -179 35 0 35 0 0 105 c0 93 2
105 19 115 61 32 81 2 81 -121 l0 -100 31 3 32 3 -2 114 c-1 63 -6 122 -11
131 -19 36 -80 47 -132 25 -16 -6 -18 -1 -18 44 0 53 -4 57 -52 44z"
      />
      <path
        d="M3762 370 c-12 -12 -22 -30 -22 -41 0 -12 -8 -23 -20 -26 -13 -3 -20
-14 -20 -29 0 -17 6 -24 20 -24 19 0 20 -7 20 -116 l0 -115 33 3 32 3 3 112 3
112 27 3 c19 2 28 9 30 26 3 19 -1 22 -27 22 -24 0 -31 4 -31 20 0 15 9 22 30
27 21 4 30 11 30 24 0 28 -78 27 -108 -1z"
      />
      <path
        d="M1470 195 l0 -176 108 3 107 3 3 32 3 32 -73 3 -73 3 -3 138 -3 137
-34 0 -35 0 0 -175z"
      />
      <path
        d="M2310 351 c0 -30 -11 -51 -26 -51 -8 0 -14 -10 -14 -25 0 -16 6 -25
15 -25 12 0 15 -17 15 -90 0 -84 2 -93 25 -116 33 -33 98 -31 103 2 2 17 -4
23 -27 27 l-31 7 0 85 0 85 30 0 c25 0 30 4 30 25 0 21 -5 25 -30 25 -28 0
-30 3 -30 35 0 32 -2 35 -30 35 -23 0 -30 -5 -30 -19z"
      />
      <path
        d="M3955 339 c-4 -19 -15 -33 -26 -36 -25 -7 -25 -43 1 -50 18 -5 20
-14 20 -99 0 -112 13 -134 80 -134 36 0 40 3 40 25 0 15 -6 25 -14 25 -33 0
-46 30 -46 106 l0 74 35 0 c30 0 35 3 35 24 0 21 -6 25 -32 28 -29 3 -33 7
-36 36 -3 26 -8 32 -27 32 -18 0 -25 -7 -30 -31z"
      />
      <path
        d="M1790 302 c-17 -6 -26 -16 -28 -35 -3 -27 -2 -27 37 -21 60 10 91 -1
91 -32 0 -21 -3 -24 -21 -19 -32 10 -87 -3 -109 -27 -29 -32 -27 -92 5 -123
28 -29 79 -33 113 -9 20 14 23 14 28 0 3 -9 17 -16 30 -16 24 0 24 0 24 114 0
106 -2 117 -23 142 -18 21 -34 27 -73 30 -27 2 -60 0 -74 -4z m100 -182 c0
-51 -70 -67 -85 -19 -9 29 12 49 51 49 31 0 34 -3 34 -30z"
      />
      <path
        d="M2093 303 c-30 -6 -63 -49 -63 -83 0 -33 28 -60 83 -80 75 -28 50
-78 -28 -60 -22 5 -40 8 -41 7 -1 -1 -5 -12 -8 -24 -6 -19 -2 -25 19 -33 84
-32 166 4 168 73 1 45 -21 70 -78 90 -28 10 -40 20 -40 33 0 16 7 19 53 18 50
-2 52 -1 52 26 0 23 -5 29 -31 34 -33 6 -49 6 -86 -1z"
      />
      <path
        d="M2531 293 c-41 -21 -56 -58 -41 -97 7 -19 26 -34 65 -51 40 -18 55
-30 55 -45 0 -24 -27 -29 -92 -16 -25 5 -28 3 -28 -23 0 -33 17 -41 88 -41 54
0 102 41 102 87 0 41 -18 60 -75 82 -35 13 -50 24 -50 37 0 16 8 19 52 19 43
-1 54 3 59 18 8 26 -5 36 -56 42 -33 5 -55 1 -79 -12z"
      />
      <path
        d="M2805 296 c-41 -18 -52 -34 -65 -89 -23 -103 28 -187 114 -187 60 0
76 8 76 36 0 22 -4 24 -43 24 -58 0 -77 21 -77 87 0 62 16 78 77 77 40 -1 43
1 43 26 0 23 -5 29 -31 34 -44 8 -60 7 -94 -8z"
      />
      <path
        d="M3424 300 c-12 -5 -25 -13 -29 -19 -9 -16 -25 -13 -25 5 0 11 -8 14
-27 12 l-28 -3 0 -135 0 -135 31 -3 32 -3 3 89 c4 102 21 132 71 132 25 0 28
3 28 35 0 37 -12 43 -56 25z"
      />
      <path d="M3560 160 l0 -140 35 0 35 0 0 140 0 140 -35 0 -35 0 0 -140z" />
    </g>
  </svg>
);
